.JobHeader {
  min-height: 250px;
  background-image: url("../../assets/jobHeader.png");
  background-position: top center;
  background-repeat: no-repeat;

  .headerJobPost {
    padding-top: 160px;

    @media (max-width: 767px) {
      padding-top: 20px;
    }

    .logoJobPost img {
      border-radius: 50%;
      height: 100px;
      width: 100px;
      filter: drop-shadow(10px 10px 35px rgba(19, 29, 29, 0.1));
    }

    .infoGymJobSpec {
      h1 {
        font-family: "BrandonTextBold";
        margin-top: 10px;
        margin-bottom: 10px;
      }
      h3 {
        font-family: "BrandonTextRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: $fittly-main-red;
        margin: 4px 0px;
      }
      h4 {
        font-family: "BrandonTextRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        color: $fittly-dark-teal;
        margin: 4px 0px;
      }

      p.location {
        font-family: "BrandonTextRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: #aea59b;
        padding-left: 17px;
        background: url("../../assets/pin.png") 0 5px no-repeat;
      }
    }
  }

  .linkBackToResults {
    font-family: "BrandonTextRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #aea59b;
    margin: 0px 12px;
    padding-left: 17px;
    background: url("../../assets/chevronLeft.png") 0 0 no-repeat;
    @media (max-width: 767px) {
      margin-bottom: 140px;
      display: block;
      color: white;
      background: url("../../assets/chevronLeftWhite.png") 0 0 no-repeat;
    }
  }
}

.jobSpecPostTop {
  padding: 40px 0 0 0;

  .specsCard {
    height: 200px;
    filter: drop-shadow(5px 20px 50px rgba(209, 127, 90, 0.1));
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
    background: url("../../assets/halfCircleBig.png") right top no-repeat,
      linear-gradient(180deg, #f9d96a -54.69%, #d17f5a 151.88%);
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;

    p.titleInfo {
      font-family: "BrandonTextRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      color: $fittly-white;
      margin: 0px;
      padding-top: 2px;
      background-position: right center;
      background-repeat: no-repeat;
      width: 100%;
      align-self: baseline;
      text-transform: capitalize;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
      }

      &.experience {
        background-image: url("../../assets/experienceBig.png");
      }

      &.salary {
        background-image: url("../../assets/salaryBig.png");
      }

      &.schedule {
        background-image: url("../../assets/scheduleBig.png");
      }
    }

    .unlockBtn {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 31px;
      padding: 4px 12px;
      color: #ffffff;
      background: transparent !important;

      @media (max-width: 767px) {
        font-size: 10px;
        line-height: 11px;
        padding: 4px 7px;
        color: #ffffff;
      }
    }

    .boxInfo {
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 767px) {
        height: 65px;
      }

      .info {
        font-family: "Manrope";
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 50px;
        text-align: center;
        color: #faf9f8;
        margin: 0;

        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 32px;
        }
      }
      .subInfo {
        font-family: "Manrope";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #faf9f8;
        margin-bottom: 0;

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      @media (max-width: 767px) {
        order: 0;
        margin-bottom: 10px;
      }
    }

    //SPECS DISABLED (SHOW LOCK ICON)
    &.disabled {
      background: url("../../assets/halfCircleLockedBig.png") right bottom no-repeat,
        linear-gradient(180deg, #ffffff -54.69%, #d17f5a 106.02%);

      .boxInfo {
        background: url("../../assets/lockBig.png") center center no-repeat;
        width: 45px;
        height: 140px;

        @media (max-width: 767px) {
          background: url("../../assets/lock.png") center center no-repeat;
          width: 45px;
          height: 60px;
        }

        .info {
          display: none;
        }
        .subInfo {
          display: none;
        }
      }
    }

    //MOBILE SPECS CARD
    @media (max-width: 767px) {
      height: 125px;
      background: url("../../assets/halfCircleBig.png") right top no-repeat,
        linear-gradient(180deg, #f9d96a -54.69%, #d17f5a 151.88%);

      &.disabled {
        background: url("../../assets/halfCircleLockedBig.png") right bottom no-repeat,
          linear-gradient(180deg, #ffffff -54.69%, #d17f5a 106.02%);
      }
    }
  }
}

.bodyJobPost {
  h2 {
    font-family: "BrandonTextRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: #404148;
    margin: 20px 0px;
    padding-top: 40px;
  }

  .boxInfoJob {
    padding: 24px;
    background: #ffffff;
    border-radius: 20px;
  }

  p.description {
    font-family: "BrandonTextRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: $fittly-dark-teal;
    margin: 10px 0px;
  }

  .iconLink {
    max-width: 20px;
    height: auto;
    margin: 0 10px;
  }

  .skillCard {
    padding: 4px 16px 4px 4px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    .imgSkill {
      width: 32px;
      height: 32px;
      background: #f2efea;
      border-radius: 4px;
      margin: 0px 12px 0 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .imgSkill img {
      width: 23px;
      height: 23px;
    }

    .infoSkill {
      font-family: "BrandonTextRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #d17f5c;
    }
  }
}
