.btn-blue {
  font-weight: 700;
  color: #ffffff;
  background-color: $fittly-blue;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  box-shadow: 10px 10px 35px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
  color: #ffffff;
  background-color: $fittly-orange;
  border: 0;
}

.btn-gray {
  font-weight: 700;
  color: $fittly-provider;
  background-color: $fittly-mid-gray;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  box-shadow: 10px 10px 35px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
  color: #ffffff;
  background-color: $fittly-orange;
  border: 0;
}

.btn-white {
  font-weight: 700;
  color: $fittly-blue;
  background-color: transparent;
  border: 0;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 32px;
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 10px 32px;
  }
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  cursor: default;
  //color: #ffffff;
  //background-color: $fittly-orange;
  //border: 0;
}

.btn-brown {
  font-weight: 700;
  color: #ffffff;
  background-color: $fittly-main-red;

  border: 1px solid $fittly-main-red;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 52px;
  box-shadow: 10px 10px 35px rgba(19, 29, 29, 0.1);
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 7px 62px;
  }
}

.btn-brown:hover,
.btn-brown:focus,
.btn-brown:active,
.btn-brown.active {
  color: #ffffff;
  background-color: #984c2c;
  border: 1px solid #984c2c;
}

.btn-login {
  font-weight: 700;
  color: $fittly-main-red;
  background-color: transparent !important;
  border: 1px solid $fittly-main-red;
  font-size: $fs18;
  font-weight: 700;
  border-radius: 50px;
  padding: 10px 42px;
  box-shadow: none;
  letter-spacing: 0.1px;

  &.little {
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 7px 32px;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 18px;
      border-radius: 50px;
      padding: 5px 10px;
    }
  }
}

.btn-login:hover,
.btn-login:focus,
.btn-login:active,
.btn-login.active {
  color: $fittly-orange;
  background-color: transparent;
  border: 1px solid $fittly-orange;
}
