.header {
  position: absolute;

  // @include media("<desktop") {
  //   display: none;
  // }

  .nav-link {
    padding: 10px;

    @media (max-width: 575px) {
      padding: 10px 5px;
    }
  }
  .iconLink {
    max-width: 20px;
    height: auto;
  }

  .ml-login-150 {
    margin-left: 150px;

    @media (max-width: 1400px) {
      margin-left: 0px;
    }
  }

  .nav-item {
    cursor: pointer;
    margin: 0 10px;
  }
}
