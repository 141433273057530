@import "include_media";
@import "~bootstrap/scss/bootstrap";
@import "./variables/fonts";
@import "./variables/colors";
@import "./global";

//GENERALS
@import "./buttons";
@import "./storeButtons";

//SECTIONS
@import "./sections/header";
@import "./sections/intro";
@import "./sections/results";
@import "./sections/jobPost";
@import "./sections/footer";
@import "./sections/filter";
@import "./sections/unlock";
