.intro {
  padding-top: 80px;
  h1 {
    font-family: "BrandonTextBold";
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 72px;
    color: $fittly-dark-teal;
    margin: 16px 0px;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
      margin: 0px;
    }
  }
  h2 {
    font-family: "BrandonTextRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $fittly-dark-teal;
    margin: 16px 0px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      margin: 0px 0 20px 0;
    }
  }
}

.storeButtonsIntro {
  min-height: 430px;

  @media (max-width: 767px) {
    min-height: 280px;
    display: block;
    background-image: url("../../assets/introMobile.png");
    background-position: top right;
    background-repeat: no-repeat;
  }

  .introBg {
    position: absolute;
    z-index: 1000;

    @media (max-width: 767px) {
      position: relative;
      display: block;
    }
  }
  .btnDonwloadApp {
    position: relative;
    z-index: 1001;
    margin-bottom: 80px;
  }
}

.titIntroH3 {
  font-family: "BrandonTextBold";
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 39px;
  color: $fittly-dark-teal;
  margin: 16px 0px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0px 0 20px 0;
  }
}
