.containerAppStore {
  padding-top: 10px;

  @include media("<=tablet") {
    width: 100%;
    text-align: center;
  }

}

.containerPlayStore {
  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
}

.btnAppStore {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  width: 158px;
  height: auto;
  margin-top: 2px;
}

.imgAppStore {
  border-radius: 13px;
  width: 158px;
  height: auto;
}

.btnPlayStore {
  max-width: 200px;
}
