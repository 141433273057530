.results {
  background-color: $fittly-bg;
  background-image: url("../../assets/bgBottomWhite.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #ebba64;

  .resultCard {
    background: #ffffff;
    border-radius: 20px;
    display: block;
    height: 100%;

    .wrapper {
      padding: 23px;
      border-radius: 20px;
      transition: 0.2s;
      height: 100%;
    }

    .wrapper:hover {
      background-color: $fittly-beige;
      transition: 0.2s;
    }

    .headerJobCard {
      padding-bottom: 15px;
      .logo {
        max-width: 46px;
        max-height: 46px;
        filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.08));
        border-radius: 5px;
        img {
          max-width: 46px;
          max-height: 46px;
        }
      }

      .info {
        padding-left: 10px;
        flex: 1;
        .gymName {
          font-family: "BrandonTextRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $fittly-neutral-black;
          margin: 0 0px 6px 0;
          padding-left: 4px;
        }

        .gymLocation {
          font-family: "BrandonTextRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $fittly-sand;
          margin: 0px 4px;
          padding-left: 17px;
          background: url("../../assets/pin.png") top left no-repeat;
        }
      }
      .shareIcon {
        padding-top: 8px;
        img {
          max-width: 27px;
        }
      }
    }

    .content {
      h5 {
        font-family: "BrandonTextRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $fittly-dark-teal;
        margin: 0;
      }

      p {
        font-family: "BrandonTextRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $fittly-sand-dark;
      }
    }

    .specs {
      .specsCard {
        height: 40px;
        filter: drop-shadow(5px 20px 50px rgba(209, 127, 90, 0.1));
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
        background: url("../../assets/halfCircle.png") right center no-repeat,
          linear-gradient(180deg, #f9d96a -54.69%, #d17f5a 151.88%);
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;

        p.titleInfo {
          font-family: "BrandonTextRegular";
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          max-width: 54px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 9px;
          color: $fittly-white;
          margin: 0px 4px;
          padding-left: 15px;
          padding-top: 2px;
          background-position: left center;
          background-repeat: no-repeat;
          order: 0;
          text-transform: capitalize;

          &.experience {
            background-image: url("../../assets/experience.png");
          }

          &.salary {
            background-image: url("../../assets/salary.png");
          }

          &.schedule {
            background-image: url("../../assets/schedule.png");
          }

          @media (max-width: 767px) {
            order: 1;
          }
        }

        .boxInfo {
          order: 1;
          .info {
            font-family: "Manrope";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 16px;
            text-align: center;
            color: #faf9f8;
            margin: 0;
          }
          .subInfo {
            font-family: "Manrope";
            font-style: normal;
            font-weight: bold;
            font-size: 9px;
            line-height: 11px;
            text-align: center;
            color: #faf9f8;
            margin-bottom: 0;
          }

          @media (max-width: 767px) {
            order: 0;
            margin-bottom: 10px;
          }
        }

        //SPECS DISABLED (SHOW LOCK ICON)
        &.disabled {
          background: url("../../assets/halfCircleLocked.png") right center no-repeat,
            linear-gradient(180deg, #ffffff -54.69%, #d17f5a 106.02%);

          .boxInfo {
            background: url("../../assets/lock.png") 0 0 no-repeat;
            width: 20px;
            height: 20px;
            .info {
              display: none;
            }
            .subInfo {
              display: none;
            }
          }
        }

        //MOBILE SPECS CARD
        @media (max-width: 767px) {
          height: 75px;
          background: url("../../assets/halfCircleMobile.png") right top no-repeat,
            linear-gradient(180deg, #f9d96a -54.69%, #d17f5a 151.88%);

          &.disabled {
            background: url("../../assets/halfCircleMobileLocked.png") right bottom no-repeat,
              linear-gradient(180deg, #ffffff -54.69%, #d17f5a 106.02%);
          }
        }
      }
    }
  }

  .navShare {
    .iconLink {
      max-width: 20px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}
