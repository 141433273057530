@font-face {
  font-family: "BrandonTextRegular";
  src: local("BrandonTextRegular"),
    url("../../assets/fonts/BrandonText-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "BrandonTextRegular";
  src: local("BrandonTextRegular"),
    url("../../assets/fonts/BrandonText-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "BrandonTextRegular";
  src: local("BrandonTextRegular"),
    url("../../assets/fonts/BrandonText-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "BrandonTextBold";
  src: local("BrandonTextBold"),
    url("../../assets/fonts/BrandonText-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "BrandonTextBold";
  src: local("BrandonTextBold"),
    url("../../assets/fonts/BrandonText-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BrandonTextBold";
  src: local("BrandonTextBold"),
    url("../../assets/fonts/BrandonText-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "BrandonTextThin";
  src: local("BrandonTextThin"),
    url("../../assets/fonts/BrandonText-Thin.otf") format("opentype");
  font-weight: thin;
}
@font-face {
  font-family: "BrandonTextThin";
  src: local("BrandonTextThin"),
    url("../../assets/fonts/BrandonText-Thin.ttf") format("truetype");
  font-weight: thin;
}
@font-face {
  font-family: "BrandonTextThin";
  src: local("BrandonTextThin"),
    url("../../assets/fonts/BrandonText-Thin.woff") format("woff");
  font-weight: thin;
}

@font-face {
  font-family: "BrandonTextLight";
  src: local("BrandonTextLight"),
    url("../../assets/fonts/BrandonText-Light.otf") format("opentype");
  font-weight: light;
}
@font-face {
  font-family: "BrandonTextLight";
  src: local("BrandonTextLight"),
    url("../../assets/fonts/BrandonText-Light.ttf") format("truetype");
  font-weight: light;
}
@font-face {
  font-family: "BrandonTextLight";
  src: local("BrandonTextLight"),
    url("../../assets/fonts/BrandonText-Light.woff") format("woff");
  font-weight: light;
}

@font-face {
  font-family: "BrandonTextRegularItalic";
  src: local("BrandonTextRegularItalic"),
    url("../../assets/fonts/BrandonText-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "BrandonTextRegularItalic";
  src: local("BrandonTextRegularItalic"),
    url("../../assets/fonts/BrandonText-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "BrandonTextRegularItalic";
  src: local("BrandonTextRegularItalic"),
    url("../../assets/fonts/BrandonText-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
