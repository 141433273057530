body {
  font-family: "BrandonTextRegular";
  // background-color: $fittly-bg;
  // background-image: url("../assets/bgBottom.png");
  // background-position: bottom center;
  // background-repeat: no-repeat;
  background-color: $fittly-bg;
  &.noBodyBG {
    background-image: none;
  }
}

a {
  text-decoration: none;
}

@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1440px !important;
  }
}

.mar-10{
 margin-right: 10px !important;
}

.headerBlock{
  flex-direction: column;
}

.cleanBtn{
  padding: 0;
  border: none;
  -webkit-appearance: none !important;
  outline: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: transparent;
}