.unlock {
  padding-top: 0px;
  background-image: url("../../assets/unlock_features/background_desktop.png?v=2");
  background-size: cover;

  background-position: center top;
  background-repeat: no-repeat;

  min-height: 100vh;
  //min-width: 100vw;
  //overflow: hidden !important;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media(">phone", "<=desktop") {
    background-image: none;
  }

  .head {
    @include media(">phone", "<=desktop") {
      display: none;
    }
    @include media("<phone") {
      display: none;
    }
    .nav-link {
      padding: 10px;
      @media (max-width: 575px) {
        padding: 10px 5px;
      }
    }
    .iconLink {
      max-width: 20px;
      height: auto;
    }
    .ml-login-150 {
      margin-left: 150px;

      @media (max-width: 1400px) {
        margin-left: 0px;
      }
    }

    .back {
      font-family: "BrandonTextBold";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #aea59b;
      margin: 0;
      padding-left: 17px;
      background: url("../../assets/chevronLeft.png") 0 0 no-repeat;
      @media (max-width: 767px) {
        margin-bottom: 140px;
        display: block;
        color: white;
        background: url("../../assets/chevronLeftWhite.png") 0 0 no-repeat;
      }
    }
  }

  .containWrapper {
    @include media("<desktop") {
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      max-width: 100%;
    }
  }

  .contain {
    @include media("<desktop") {
      width: 100%;
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .containInc {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @include media("<=tablet") {
      justify-content: center;
      align-items: center;
    }
  }

  h1 {
    margin-top: 80px;
    font-size: 40px;
    font-family: "BrandonTextBold";

    @include media("<=tablet") {
      background-image: none;
      font-size: 30px;
      margin-top: 40px;
    }
  }

  .devicePicture {
    @include media(">phone", "<=desktop") {
      display: none;
    }
    @include media("<phone") {
      display: none;
    }

    height: 812px;
    img {
      height: 100%;
    }
  }

  .deviceMobile {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
    @include media(">=desktop") {
      display: none;
    }
    @include media("<phone") {
      display: flex;
      flex-direction: column;
    }

    .btnContainer {
      flex-direction: column;
      margin-top: -60px;
    }
  }

  .linkBox {
    display: flex;
    @include media("<=tablet") {
      display: none;
    }
  }

  .checklist {
    margin-top: 45px;
    list-style-type: none;

    @include media("<=desktop") {
      padding: 0;
      padding-bottom: 100px;
      margin: 0;
    }

    img {
      margin-right: 10px;
    }
    li {
      margin-top: 10px;
      @include media(">phone", "<=desktop") {
        font-size: 12px;
      }
    }
  }

  .containInc {
    margin: 0 auto;
    @include media("<=tablet") {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
