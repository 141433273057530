.filters {
  position: relative;
  z-index: 1060;
}
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  text-align: left;
}
.geosuggest__input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: $fittly-tan;
  color: #fff !important;

  ::placeholder {
    color: #fff !important;
  }
  outline: none !important;
  min-height: 38px !important;
}

.geosuggest__input::placeholder {
  color: #fff !important;
  opacity: 1;
}

.geosuggest__input:focus {
  border-color: $fittly-tan !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(241, 162, 116, 0.87) !important;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: 5px;
  background: #fff;
  border: 2px solid $fittly-tan;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
  border-radius: 10px;
  scrollbar-color: $fittly-tan $fittly-mid-gray;
  scrollbar-width: thin;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: $fittly-tan;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.inputFilter {
  // display: block;
  // width: 100%;
  // padding: 0.375rem 0.75rem;
  // font-size: 1rem;
  // font-weight: 400;
  // line-height: 1.5;
  // color: #212529;
  // background-color: #fff;
  // background-clip: padding-box;
  // border: 1px solid $fittly-tan;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // border-radius: 0.25rem;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: $fittly-tan;
  color: #fff !important;
  border-radius: 20px !important;
  border: none !important;
  min-height: 38px !important;

  &.withIcon {
    background-image: url("../../assets/search.png");
    background-position: 7px 8px;
    background-repeat: no-repeat;
    padding-left: 40px;
    background-color: $fittly-white !important;
    color: $fittly-orange !important;

    @media (min-width: 1200px) {
      max-width: 280px;
      float: right;
    }

    @media (max-width: 767px) {
      min-width: auto;
      background-position: 10px 12px;
      margin-right: 10px;
    }
  }
}

.inputFilter.disabled {
  background-color: #ded5c8 !important;
  cursor: default;
  pointer-events: none;
}

.inputFilter.withIcon::placeholder {
  color: $fittly-tan !important;
}

.inputFilter.withIcon:focus {
  color: $fittly-tan !important;
}

.inputFilter:focus {
  background-color: $fittly-tan;
  color: #fff;
  border: none;
}

.inputFilter::placeholder {
  color: #fff;
}

.inputFilter__control {
  border: 1px solid $fittly-tan !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 20px !important;
  z-index: 9999 !important;

  background-color: $fittly-tan !important;
  color: #fff !important;

  .inputFilter__single-value {
    color: #fff !important;
  }

  .inputFilter__placeholder {
    background-color: $fittly-tan;
    color: #fff !important;
  }

  .inputFilter__multi-value {
    background-color: $fittly-sand;
    color: #fff !important;
  }
}

.inputFilter__menu {
  border-radius: 10px !important;
}

.inputFilter__menu-option {
  border-radius: 10px !important;
}

.inputFilter__menu-list {
  border-radius: 10px !important;
  scrollbar-color: $fittly-tan $fittly-mid-gray !important;
  scrollbar-width: thin !important;
  border: 2px solid $fittly-tan;
}

.inputFilter__option:hover,
.inputFilter__option:focus {
  background: #f5f5f5;
}
.inputFilter__option:active {
  background: $fittly-tan !important;
  color: #fff;
}
.inputFilter__option-active:hover,
.inputFilter__option-active:focus {
  background: #ccc;
}

.form-control:focus {
  border-color: $fittly-tan;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(241, 162, 116, 0.87);
}

.inputFilter__control--is-focused {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(241, 162, 116, 0.87) !important;
}

.inputFilter__indicator {
  color: #fff !important;
}

.inputFilter__indicator:hover {
  color: $fittly-orange !important;
}

.inputFilter__indicator-separator {
  display: none;
}

.inputFilter__multi-value__remove:hover {
  background-color: $fittly-orange !important;
  color: #fff !important;
}
